import { format } from "date-fns";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { Link, graphql } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Lightbox from "yet-another-react-lightbox";
import { OurProcess } from "../components/our-process";
import AboutOurProducts from "../components/partials/about-our-products";
import { TryQuoteBuilderBanner } from "../components/partials/try-quote-builder-banner";
import GoogleReviews from "../components/sections/google-reviews";
import Guarantee from "../components/sections/guarantee";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import SimpleProductTemplate from "./simple-product";
import VariableProductTemplate from "./variable-product";
import VariableProductLinerTemplate from "./variable-product-liner";

import "yet-another-react-lightbox/styles.css";

const ProductTemplate = (props) => {
  const currentProduct = props.data.wpProduct;

  const [productAttributes, setProductAttributes] = useState(null);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(false);
  const [seoTitle, setSeoTitle] = useState(currentProduct.seo?.title);
  const [seoDescription, setSeoDescription] = useState(
    currentProduct.seo?.metaDesc
  );

  const { onProductView } = useContext(CartContext);

  useEffect(() => {
    const variationOptions = [];
    // get all attributes and values
    if (props.data.wpProduct.product.variations) {
      props.data.wpProduct.product.variations.map((variation) => {
        if (
          variation.variationAttributes &&
          variation.variationAttributes.length
        ) {
          variation.variationAttributes.map((variationAttribute) => {
            // do the variation option and value already exist
            const currentVariationOption = variationOptions.filter(
              (variationOptionValue) => {
                return (
                  variationOptionValue.name ===
                  variationAttribute.variationAttributeName
                );
              }
            );

            const workingVariationOption = currentVariationOption.length
              ? currentVariationOption[0]
              : [];

            workingVariationOption.name =
              variationAttribute.variationAttributeName;
            if (!workingVariationOption.options) {
              workingVariationOption.options = [];
            }
            if (workingVariationOption.options.length) {
              if (
                !workingVariationOption.options.includes(
                  variationAttribute.variationAttributeValue
                )
              ) {
                workingVariationOption.options.push(
                  variationAttribute.variationAttributeValue
                );
              }
            } else {
              workingVariationOption.options = [
                variationAttribute.variationAttributeValue,
              ];
            }

            const appendToVariationOption = variationOptions.filter(
              (variationOptionValue) => {
                return (
                  variationOptionValue.name ===
                  variationAttribute.variationAttributeName
                );
              }
            );
            if (appendToVariationOption.length) {
              variationOptions.map(
                (variationOptionValue, variationOptionIndex) => {
                  if (
                    variationOptionValue.name === workingVariationOption.name
                  ) {
                    variationOptions[variationOptionIndex] =
                      workingVariationOption;
                  }
                  return true;
                }
              );
              return true;
            } else {
              variationOptions.push({
                name: workingVariationOption.name,
                options: workingVariationOption.options,
              });
            }
            return true;
          });
        }
        return true;
      });

      setProductAttributes(variationOptions);
    }

    if (props.data.wpProduct.product.type !== "variable") {
      getImages(props.data.wpProduct.product.imageGallery);
    }
  }, [
    props.data.wpProduct.product.imageGallery,
    props.data.wpProduct.product.type,
    props.data.wpProduct.product.variations,
  ]);

  const getImages = (imgs) => {
    if (!imgs) return [];

    return setImages(
      imgs.map((image) => ({
        altText: image.altText,
        src: image.localFile.publicURL,
      }))
    );
  };

  const saleEndsDate = lastDayOfMonth(new Date());
  const saleEnds = {
    date: format(saleEndsDate, "MMMM d"),
    days: differenceInCalendarDays(lastDayOfMonth(new Date()), new Date()),
  };

  const updateVariation = (variation) => {
    setSeoTitle(variation.variationSeoTitle);
    setSeoDescription(variation.variationSeoDescription);
  };

  const offers = !currentProduct.product.variations
    ? {
        "@type": "Offer",
        priceCurrency: "USD",
        price: `${
          currentProduct.product.salePrice
            ? currentProduct.product.salePrice
            : currentProduct.product.basePrice
        }`,
        url: `${process.env.PUBLIC_URL}/${currentProduct.slug}/`,
        availability: "http://schema.org/InStock",
      }
    : {
        "@type": "AggregateOffer",
        lowPrice: currentProduct.product?.variations?.reduce(
          (previous, variation) => {
            return variation?.variationSalePrice &&
              variation?.variationSalePrice < previous
              ? variation?.variationSalePrice
              : previous;
          },
          10000
        ),
        highPrice: currentProduct.product?.variations?.reduce(
          (previous, variation) => {
            return variation?.variationSalePrice &&
              variation?.variationSalePrice > previous
              ? variation?.variationSalePrice
              : previous;
          },
          0
        ),
        priceCurrency: "USD",
        availability: "InStock",
        url: `${process.env.PUBLIC_URL}/${currentProduct.slug}/`,
        offerCount: currentProduct.product.variations
          ? currentProduct.product.variations.length
          : null,
      };
  let models = !currentProduct.product.variations
    ? {}
    : currentProduct.product.variations.map((value) => {
        return {
          "@type": "ProductModel",
          name: value.variationName,
          color:
            value.variationAttributes?.at(1)?.variationAttributeValue ||
            undefined,
          offers: {
            "@type": "Offer",
            price: value.variationSalePrice
              ? value.variationSalePrice
              : value.variationBasePrice,
            name: value.variationName,
            availability: "InStock",
          },
        };
      });

  useEffect(() => {
    onProductView({
      ...props.data.wpProduct,
      ...props.data?.wpProduct?.product,
    });
  }, []);

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />
      <Helmet>
        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": "${currentProduct.title}",
            "image": "${process.env.PUBLIC_URL}/${
          currentProduct.featuredImage.node.localFile.publicURL
        }",
            "description": "${seoDescription}",
            "sku": "${currentProduct.product.sku}",
            "brand": "${process.env.COMPANY_NAME}",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "399"
            },
            "offers": ${JSON.stringify(offers)},
            "model": ${JSON.stringify(models)}
        }`}</script>
      </Helmet>
      <div className="container mx-auto px-4 lg:px-0 lg:my-12">
        <div className="rounded overflow-hidden">
          <TryQuoteBuilderBanner source={currentProduct.product.category[0]} />
        </div>
        <div className="text-grey font-bold uppercase flex gap-1 py-4 text-sm">
          <Link
            className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}
            to="/products/"
          >
            Products
          </Link>
          /<span>Window Well {currentProduct.product.category[0]}</span>
        </div>
        <div className="grid lg:grid-cols-2 gap-4 lg:gap-12 mb-8 lg:mb-24 items-start">
          <div className="w-full lg:sticky top-4">
            {!images.length && <div className="bg-grey-lighter aspect-video" />}
            {!!images.length && (
              <>
                <button
                  type="button"
                  onClick={() => {
                    setSlideIndex(0);
                    setOpen(true);
                  }}
                  className="block w-full mb-3"
                >
                  <img src={images[0].src} alt="product" className="w-full" />
                </button>
                {images.length > 1 && (
                  <div className="grid grid-cols-5 gap-2">
                    {images.map((img, index) => {
                      // don't duplicate the first image
                      if (index === 0) return null;

                      if (index === 5)
                        return (
                          <button
                            className="bg-grey-lighter text-sm rounded text-grey-dark flex items-center justify-center"
                            key={index}
                            type="button"
                            onClick={() => {
                              setSlideIndex(index);
                              setOpen(true);
                            }}
                          >
                            +5
                          </button>
                        );

                      if (index > 5) return;

                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={() => {
                            setSlideIndex(index);
                            setOpen(true);
                          }}
                        >
                          <img
                            alt={img.altText || "Product"}
                            className="object-cover rounded h-24 w-full"
                            src={img.src}
                          />
                        </button>
                      );
                    })}
                  </div>
                )}
                <Lightbox
                  close={() => setOpen(false)}
                  controller={{
                    closeOnPullDown: true,
                    closeOnBackdropClick: true,
                  }}
                  index={slideIndex}
                  open={open}
                  slides={images}
                />
              </>
            )}
          </div>
          <div className="mt-8 lg:mt-0 w-full mb-8">
            {currentProduct.product.type === "simple" && (
              <SimpleProductTemplate
                currentProduct={currentProduct}
                saleEnds={saleEnds}
              />
            )}
            {/* non liner products */}
            {currentProduct.product.type === "variable" &&
              !currentProduct.product.category.find(
                (cat) => cat === "liners"
              ) && (
                <VariableProductTemplate
                  currentProduct={currentProduct}
                  saleEnds={saleEnds}
                  productAttributes={productAttributes}
                  updateGallery={getImages}
                  updateVariation={updateVariation}
                />
              )}
            {/* liners */}
            {currentProduct.product.type === "variable" &&
              currentProduct.product.category.find(
                (cat) => cat === "liners"
              ) && (
                <VariableProductLinerTemplate
                  currentProduct={currentProduct}
                  saleEnds={saleEnds}
                  productAttributes={productAttributes}
                  updateGallery={getImages}
                  updateVariation={updateVariation}
                />
              )}
          </div>
        </div>
      </div>
      {!!currentProduct.product.category.find(
        (category) => category === "covers"
      ) && (
        <div className="container mx-auto px-4 lg:px-0 pb-8">
          <OurProcess />
        </div>
      )}
      <Guarantee />
      <AboutOurProducts />
      <GoogleReviews />
    </>
  );
};

export default ProductTemplate;

export const productQuery = graphql`
  query ($id: String!) {
    wpProduct(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      slug
      featuredImage {
        node {
          title
          localFile {
            publicURL
          }
        }
      }
      product {
        mainH1
        basePrice
        salePrice
        sku
        taxable
        type
        category
        imageGallery {
          altText
          title
          localFile {
            publicURL
          }
        }
        variations {
          variationName
          variationContent
          variationSeoTitle
          variationSeoDescription
          variationBasePrice
          variationSalePrice
          variationBase
          variationColor
          variationStyle
          variationAttributes {
            variationAttributeName
            variationAttributeValue
          }
          variationSku
          variationSkus {
            variationSkusSku
          }
          variationDescription
          variationShortDescription
          variationFeaturedImage {
            title
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          variationImageGallery {
            altText
            title
            localFile {
              publicURL
            }
          }
          outOfStock
          outOfStockShortDescription
          outOfStockLongDescription
          discounted
        }
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
