import React from "react";
import { Link } from "gatsby";
import { TiClipboard } from "react-icons/ti";

export const TryQuoteBuilderBanner = ({ source = "" }) => {
  return (
    <div
      className={`flex justify-end py-2 px-12 bg-${process.env.THEME_COLOR_PRIMARY} text-white`}
    >
      <Link
        to={source ? `/quote-builder/?source=${source}` : "/quote-builder/"}
        className={`flex items-center gap-1 font-medium bg-white py-2 px-4 rounded text-${process.env.THEME_COLOR_PRIMARY}-darker`}
      >
        <span>Build a Quote</span>
        <TiClipboard className="text-lg" />
      </Link>
    </div>
  );
};
